<template>
	<div class="welcome">
		<div class="content">
			<div class="btns">
				<div class="btn" @click="$router.push('/pc')">
					<img class="icon" src="../assets/keyboard.png" alt="">
					<div class="enter">
						共创
					</div>
				</div>
				<div class="btn" @click="$router.push('/')">
					<img class="icon" src="../assets/wechat.png" alt="">
					<img class="qr" src="../assets/qr.png" alt="">
				</div>
			</div>
			<div class="tips">扫码或点击“共创”，即代表同意<a @click="xieyi1 = true">《10000条龙共创协议》</a>和<a @click="xieyi2 = true">《隐私保护声明》</a>
			</div>
		</div>

		<a-modal title="共创协议" :visible="xieyi1" @ok="xieyi1 = false" @cancel="xieyi1 = false" cancelText="关闭" okText="同意并关闭"
			:width="1000" :bodyStyle="{ maxHeight: '70vh', overflow: 'auto' }">
			<div style="text-indent: 2em">
				<h3>上海王小慧艺术中心共创协议</h3>
				<p>欢迎您来到上海王小慧艺术中心。</p>
				<p>请您仔细阅读以下条款，如果您对本协议的任何条款表示异议，您可以选择不参与共创活动。当您参与共创活动，均意味着您（即「用户」）完全接受本协议项下的全部条款。</p>
				<p>1. 共创艺术活动属于本次展览的一部分，所有共创作品仅用于本次展览活动与展览信息的传播，不能用于任何其他商业用途。</p>
				<p>2. 主办方提供的共创活动基础作品的版权属于艺术家</p>
				<p>3. 在共创活动中产生的共创作品属于参与者。作品中抣有作者姓名与编号。</p>
				<p>4. 参与本此共创活动者表明参与者同意遵守以上规则。</p>
				<p>5. 用户不得利用上海王小慧艺术中心服务制作、上载、复制、发布、传播或者转载如下内容：</p>
				<p>(1) 反对宪法所确定的基本原则的；</p>
				<p>(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
				<p>(3) 损害国家荣誉和利益的；</p>
				<p>(4) 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
				<p>(5) 侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；</p>
				<p>(6) 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
				<p>(7) 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
				<p>(8) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
				<p>(9) 侮辱或者诽谤他人，侵害他人合法权益的；</p>
				<p>(10) 含有法律、行政法规禁止的其他内容的信息。</p>

				<h3>免责声明</h3>
				<p>1. 上海王小慧艺术中心不能对用户提交的内容合规性进行保证。</p>
				<p>2. 作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。上海王小慧艺术中心不承担任何法律及连带责任。</p>
				<p>3. 上海王小慧艺术中心不保证网络服务一定能满足用户的要求，也不保证网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作保证。</p>
				<p>4. 对于因不可抗力或上海王小慧艺术中心不能控制的原因造成的网络服务中断或其它缺陷，上海王小慧艺术中心不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</p>

				<h3>协议修改</h3>
				<p>1.
					根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，上海王小慧艺术中心有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，上海王小慧艺术中心将会直接本页面上公布修改之后的协议内容，该公布行为视为上海王小慧艺术中心已经通知用户修改内容。
				</p>
				<p>2. 如果不同意上海王小慧艺术中心对本协议相关条款所做的修改，用户有权并应当停止使用。并通知删除产生的有关内容及信息。
					联系方式：50043741@qq.com 上海王小慧艺术中心将在15个工作日内处理完毕并回复用户。
				</p>
			</div>
		</a-modal>

		<a-modal title="个人信息保护声明" :visible="xieyi2" @ok="xieyi2 = false" @cancel="xieyi2 = false" cancelText="关闭"
			okText="同意并关闭" :width="1000">
			<div style="text-indent: 2em">
				<h3>个人信息保护声明</h3>
				<p>我们根据《中华人民共和国网络安全法》《中华人民共和国个人信息保护法》《中华人民共和国数据安全法》《常见类型移动互联网应用程序必要个人信息范围规定》等法律法规，并参考《信息安全技术 个人信息安全规范》（GB/T
					35273-2020）等国家标准，制定上海王小慧艺术中心《个人信息保护声明（以下简称「本声明」）并致力于保护您的个人信息安全。</p>
				<p>本声明解释了上海王小慧艺术中心如何收集、使用、存储和共享您的个人信息。请仔细阅读本声明,您使用上海王小慧艺术中心服务即表示同意上海王小慧艺术中心按照本声明收集、使用您的个人信息。</p>
				<h4>个人信息的收集与使用</h4>
				<p>上海王小慧艺术中心收集您的个人信息主要包括姓名、电话号码。我们仅会在法律允许和必要的情况下收集和使用您的个人信息,这些信息将用于网站功能的正常运营我们不会将您的个人信息提供给任何第三方。</p>
				<h4>个人信息的存储与保护</h4>
				<p>上海王小慧艺术中心采取严格的保密措施来保护您的个人信息安全。我们使用数据加密技术对敏感信息进行加密,限制内部人员访问您的个人信息,并提供严格的安全培训来确保信息安全。但是,基于网路服务的特性,我们不能保证信息绝对安全。
				</p>
				<h4>个人信息的共享与披露</h4>
				<p>
					除法律法规要求或为维护上海王小慧艺术中心权益所必要的情况外,您的个人信息不会以任何形式共享、租借或出售给第三方。我们可能会根据法律要求,或在诚信的基础上,与第三方合作伙伴共享您的某些个人信息。但我们将要求他们按照上海王小慧艺术中心的标准来具体保护您的个人信息。
				</p>
				<h4>信息主体的权利</h4>
				<p>您有权查询删除上海王小慧艺术中心所存储的您的个人信息。如需行使这些权利,请联系上海王小慧艺术中心客服。我们将在法律允许的范围内努力满足您的请求。
					邮箱：50043741@qq.com
				</p>
				<h4>声明变更</h4>
				<p>
					上海王小慧艺术中心有权在任何时间修改本声明。修改后的声明一旦在上海王小慧艺术中心公布即有效力。如果您不同意所做的修改,应立即停止使用上海王小慧艺术中心服务。如果您继续使用上海王小慧艺术中心服务,则视为同意并接受修改后的条款。
				</p>
				<h4>法律效力</h4>
				<p>本声明受中华人民共和国法律保护。如发生法律纠纷,适用中华人民共和国法律。</p>
			</div>
		</a-modal>
	</div>
</template>

<style lang='scss' scoped>
.back {
	position: absolute;
	left: 40px;
	bottom: 40px;
	width: 60px;
	cursor: pointer;
}

.welcome {
	width: 100vw;
	height: 100vh;
	padding: 0;
	margin: 0;
	background-image: url('../assets/bg.png');
	background-size: cover;
	background-position: center;
	position: relative;

	.content {
		width: 30%;
		height: 160px;
		position: absolute;
		left: 35%;
		bottom: 90px;
		display: flex;
		flex-direction: column;

		.btns {
			width: 100%;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.btn {
				display: flex;
				align-items: center;
				justify-content: space-around;
				cursor: pointer;

				.icon {
					width: 60px;
					margin-right: 20px;
				}

				.qr {
					width: 100px;
					height: 100px;
				}

				.enter {
					width: 100px;
					height: 100px;
					border: 3px solid #333;
					color: #333;
					border-radius: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 26px;
					font-weight: 600;
					background-image: linear-gradient(to right, #F3D7A7, #fff);
				}
			}
		}

		.tips {
			text-align: center;
			font-size: 16px;
			color: #333;
		}
	}
}
</style>

<script>
export default {
	data() {
		return {
			xieyi1: false,
			xieyi2: false
		}
	},

	created() {

	},

	methods: {
		goto() {
			document.getElementById('form').scrollIntoView()
		}
	}
}
</script>